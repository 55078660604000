import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BehaviorSubject, Subscription } from 'rxjs';
import { GetHotelsQuery, HotelClient, HotelGridData } from '../../../core/autogenerated-clients/api-client';
import { SelectedHotelGroupService } from '../../../core/services/hotel.service';

@Component({
  selector: 'app-hotel-id-picker',
  templateUrl: './hotel-id-picker.component.html',
  styleUrls: ['./hotel-id-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotelIdPickerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() hotelGroupId: string = null;
  @Input() hotelIdControl: UntypedFormControl;

  @Output() hotelSelectionChanged: EventEmitter<string> = new EventEmitter<string>();

  hotelAutocompleteControl: UntypedFormControl;

  hotels: HotelGridData[] = [];
  hotelChangedSubscription: Subscription;
  filteredHotels$: BehaviorSubject<HotelGridData[]> = new BehaviorSubject<HotelGridData[]>([]);
  isLoadingHotels$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _hotelClient: HotelClient,
    private _selectedHotelGroupService: SelectedHotelGroupService
  ) { }

  ngOnInit(): void {
    this._initAutocompleteControls();

    if (!!this.hotelGroupId) {
      this._loadHotelGroupData(this.hotelGroupId);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filteredHotels$.next(this.hotels ?? []);

    if (changes.hotelGroupId && !changes.hotelGroupId.firstChange) {
      this._loadHotelGroupData(changes.hotelGroupId.currentValue);
    }
  }

  ngOnDestroy(): void {
    if (this.hotelChangedSubscription) {
      this.hotelChangedSubscription.unsubscribe();
    }
  }

  displayHotelName = (hotelId: string) => {
    if (!hotelId) {
      return null;
    }

    return this.hotels?.find(h => h.id === hotelId)?.name;
  }

  onHotelSelected(optionEvent: MatAutocompleteSelectedEvent): void {
    let hotelId: string = null;

    if (optionEvent && !!optionEvent.option) {
      hotelId = optionEvent.option.value;
    }

    this._selectHotelId(hotelId);
    this.hotelSelectionChanged.next(hotelId);
  }

  clearHotelSelection(): void {
    this._selectHotelId(null);
    this.hotelAutocompleteControl.setValue(null);
  }

  private _loadHotelGroupData(groupId: string): void {
    if (!groupId) {
      this.hotels = [];
      this.filteredHotels$.next([]);
      this.hotelIdControl.setValue(null);
      return;
    }

    this.hotelAutocompleteControl.disable();
    this.isLoadingHotels$.next(true);
    this._hotelClient.getHotels(new GetHotelsQuery({})).subscribe(
      (hotels: HotelGridData[]) => {
        this.hotels = hotels;
        this.filteredHotels$.next([...hotels]);

        let hotelId: string = this.hotelIdControl.value;
        if (!!hotelId) {
          let hotel = this.hotels.find(h => h.id === hotelId);

          this.hotelAutocompleteControl.setValue(hotel.id);
          this.hotelAutocompleteControl.updateValueAndValidity();
        }
        else {
          this.hotelAutocompleteControl.setValue(null);
          this.hotelAutocompleteControl.updateValueAndValidity();
        }

        this.hotelAutocompleteControl.enable();
      },
      (error: Error) => {
        this.isLoadingHotels$.next(false);
        this.hotelAutocompleteControl.enable();
      },
      () => {
        this.isLoadingHotels$.next(false);
      }
    );
  }

  private _selectHotelId(id: string) {
    this._selectedHotelGroupService.storeSelectedHotelId(id);
    this.hotelIdControl.setValue(id);
  }

  private _initAutocompleteControls(): void {
    this.hotelAutocompleteControl = new UntypedFormControl();

    this.hotelChangedSubscription = this.hotelAutocompleteControl.valueChanges
      .subscribe(
        (group: any) => {
          this.filteredHotels$.next(this._filter(group));
        }
      );
  }

  private _filter(value): HotelGridData[] {
    if (!value) {
      return this.hotels;
    }

    const filterValue = value.toLowerCase();
    return this.hotels.filter(a => a.name.toLowerCase().indexOf(filterValue) >= 0);
  }
}
