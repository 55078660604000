import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HotelGridData, HotelGroupData, HotelGroupGridData } from "../autogenerated-clients/api-client";
//import { HotelItemData } from "../autogenerated-clients/api-client";

@Injectable({ providedIn: 'root' })
export class HotelService {
  private _localStorageHotelIdKey: string = "__RCPHID";
  private _hotels: Array<any>;

  public hotelId$: Observable<string>;
  private _hotelId$: BehaviorSubject<string>;

  get hotels(): Array<any> {
    return [...this._hotels];
  }

  constructor() {
    this._hotels = [];

    this._hotelId$ = new BehaviorSubject<string>(null);
    this.hotelId$ = this._hotelId$.asObservable();
  }

  public getSelectedHotelId(): string {
    return this._hotelId$.value;
  }

  public getSelectedHotel(): any {
    return this.getHotel(this._hotelId$.value);
  }

  public selectHotelId(hotelId: string) {
    let hotel = this._hotels.find(h => h.id === hotelId);
    if (hotel === null) {
      throw "Unknown hotel";
    }

    this._hotelId$.next(hotelId);
    this._setHotelIdToLocalStorage(hotelId);
  }

  public setHotels(hotels: Array<any>) {
    this._hotels = hotels.sort((hotel1, hotel2) => { return hotel1.name > hotel2.name ? 1 : -1; });;

    let hotelId = this._getHotelIdFromLocalStorage();
    if (hotelId === null && this._hotels && this._hotels.length > 0) {
      hotelId = this._hotels[0].id;
      this._setHotelIdToLocalStorage(hotelId);
    }

    this._hotelId$.next(hotelId);
  }

  public getHotels(): Array<any> {
    return [...this._hotels];
  }

  public getHotel(hotelId: string): any {
    return this._hotels.find(h => h.id === hotelId);
  }

  public getDefaultHotel(): any {
    return this._hotels[0];
  }

  private _getHotelIdFromLocalStorage(): string {
    return localStorage.getItem(this._localStorageHotelIdKey);
  }

  private _setHotelIdToLocalStorage(hotelId: string): void {
    localStorage.setItem(this._localStorageHotelIdKey, hotelId);
  }
}

@Injectable({ providedIn: 'root' })
export class SelectedHotelGroupService {
  private _selectedHotelIdKey: string = "__#RCA_SHID";
  private _selectedHotelGroupIdKey: string = "__#RCA_SHGID";

  constructor() {
  }

  storeSelectedHotelId(hotelId: string): void {
    if (!hotelId) {
      localStorage.removeItem(this._selectedHotelIdKey);
    }
    else {
      localStorage.setItem(this._selectedHotelIdKey, hotelId);
    }
  }

  storeSelectedHotelGroupId(hotelGroupId: string): void {
    if (!hotelGroupId) {
      localStorage.removeItem(this._selectedHotelGroupIdKey);
    }
    else {
      localStorage.setItem(this._selectedHotelGroupIdKey, hotelGroupId);
    }
  }

  getSelectedHotelId(): string {
    return localStorage.getItem(this._selectedHotelIdKey);
  }
  
  getSelectedHotelGroupId(): string {
    return localStorage.getItem(this._selectedHotelGroupIdKey);
  }
}
