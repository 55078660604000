import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, Inject, Optional, OnChanges, SimpleChanges } from '@angular/core';
import { AuthorizeService, IUser } from '../../../../api-authorization/authorize.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { API_BASE_URL } from '../../../core/autogenerated-clients/api-client';
import { map } from 'rxjs/operators';

export class FileUploadData {
  file: File;
  temporaryImageUrl: string; // URL.createObjectURL(event.target.files[0]);
  imageUrl: string; // this value is set after successful upload to the server
  sanitizedFileName: string;
  isUploaded: boolean;
  hasError: boolean;
  message: string;
  isUploadInProgress: boolean;
  isImage: boolean;
  displayText: string;
}
export class FileUploadResponseProgressData {
  status: string;
  progressValue: number;
  uploadFinished: boolean;
  response: Array<any>;
  eventType: string;
}
export class FileUploadedData {
  imageUrl: string;
  fileName: string;
}
export class FileDetails extends FileUploadedData {
  id: string;
  isImage: boolean;
  imageUrl: string;
  displayText: string;
}

export class FilesChangedData {
  id: string;
  isNewlyUploaded: boolean;
  isStillUploading: boolean;
  fileName: string;
  imageUrl: string;
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnChanges {
  @ViewChild('hiddenFileUpload', { static: true }) fileUploadElement: ElementRef;

  @Input() currentlyUploadingFiles: Array<FileDetails> = [];
  @Input() temporaryUploadedFiles: Array<FileDetails> = [];
  @Input() uploadedFiles: Array<FileDetails> = [];

  @Output() filesChanged: EventEmitter<Array<FilesChangedData>> = new EventEmitter<Array<FilesChangedData>>();

  private _progress$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _httpClient: HttpClient;

  public progress$: Observable<number>;
  public currentlyUploadingFiles$: BehaviorSubject<Array<FileDetails>> = new BehaviorSubject<Array<FileDetails>>([]);
  public temporaryUploadedFiles$: BehaviorSubject<Array<FileDetails>> = new BehaviorSubject<Array<FileDetails>>([]);
  public uploadedFiles$: BehaviorSubject<Array<FileDetails>> = new BehaviorSubject<Array<FileDetails>>([]);

  constructor(@Inject(HttpClient) httpClient: HttpClient, private toastr: ToastrService, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this._httpClient = httpClient;

    this.progress$ = this._progress$.asObservable();
  }
  ngOnInit(): void {
    this.currentlyUploadingFiles$.next(this.currentlyUploadingFiles);
    this.temporaryUploadedFiles$.next(this.temporaryUploadedFiles);
    this.uploadedFiles$.next(this.uploadedFiles);

    //this.currentlyUploadingFiles$.subscribe(value => this._emitUploadedFiles());
    //this.temporaryUploadedFiles$.subscribe(value => this._emitUploadedFiles());
    this.uploadedFiles$.subscribe(value => this._emitUploadedFiles());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentlyUploadingFiles) {
      this.currentlyUploadingFiles$.next(this.currentlyUploadingFiles);
    }
    if (changes.temporaryUploadedFiles) {
      this.temporaryUploadedFiles$.next(this.temporaryUploadedFiles);
    }
    if (changes.uploadedFiles) {
      this.uploadedFiles$.next(this.uploadedFiles);
    }
  }



  private _emitUploadedFiles() {
    var fileChanges: Array<FilesChangedData> = [];

    for (let f of this.uploadedFiles$.value) {
      fileChanges.push({ id: f.id, fileName: f.fileName, isNewlyUploaded: false, isStillUploading: false, imageUrl: f.imageUrl });
    }
    for (let f of this.temporaryUploadedFiles$.value) {
      fileChanges.push({ id: null, fileName: f.fileName, isNewlyUploaded: true, isStillUploading: false, imageUrl: f.imageUrl });
    }
    for (let f of this.currentlyUploadingFiles$.value) {
      fileChanges.push({ id: null, fileName: f.fileName, isNewlyUploaded: false, isStillUploading: true, imageUrl: f.imageUrl });
    }

    this.filesChanged.next(fileChanges);
  }

  filesSelected(files: FileList) {
    const fileUploads: Array<FileUploadData> = [];
    const tempFileDetails: Array<FileDetails> = [];

    for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
      const file: File = files.item(fileIndex);
      const fileExtensionData = this._getFileExtensionData(file.name);
      const fileNameWithoutExtension = this._getFileNameWithoutExtension(file.name);
      const sanitizedFileName = this._sanitizeFileName(fileNameWithoutExtension, fileExtensionData.extension);

      const fileUploadData: FileUploadData = {
        file: file,
        temporaryImageUrl: URL.createObjectURL(file),
        imageUrl: '',
        sanitizedFileName: sanitizedFileName,
        isUploadInProgress: false,
        isUploaded: false,
        hasError: false,
        message: '',
        displayText: "." + fileExtensionData.extension,
        isImage: fileExtensionData.isImage
      };

      fileUploads.push(fileUploadData);

      const fileDetails: FileDetails = {
        id: "",
        fileName: fileUploadData.sanitizedFileName,
        imageUrl: fileUploadData.temporaryImageUrl,
        isImage: fileUploadData.isImage,
        displayText: fileUploadData.displayText
      };

      tempFileDetails.push(fileDetails);
    }

    this.currentlyUploadingFiles$.next(tempFileDetails);

    this.uploadFilesWithProgress(fileUploads).subscribe((progressResponse: FileUploadResponseProgressData) => {
      if (!progressResponse.uploadFinished && progressResponse.status == "upload_in_progress") {
      }
      else if (progressResponse.uploadFinished && progressResponse.status == "upload_finished") {
        let uploadedFileDetails: Array<FileDetails> = [];

        for (const fileUploadItemResponse of progressResponse.response) {
          if (fileUploadItemResponse.hasError) {
            this.toastr.error(fileUploadItemResponse.message);
            continue;
          }
          else if (fileUploadItemResponse.alreadyExists) {
            this.toastr.info(fileUploadItemResponse.message);
          }

          var fileExtensionData = this._getFileExtensionData(fileUploadItemResponse.fileName);
          var fileDetails: FileDetails = {
            fileName: fileUploadItemResponse.fileName,
            imageUrl: fileUploadItemResponse.imageUrl,
            id: null,
            displayText: '.' + fileExtensionData.extension,
            isImage: fileExtensionData.isImage
          };

          if (!fileUploadItemResponse.alreadyExists) {
            this.toastr.success(fileUploadItemResponse.message);
          }

          uploadedFileDetails.push(fileDetails);
        }

        this._clearFileUploadElement();
        this.temporaryUploadedFiles$.next([...this.temporaryUploadedFiles$.value, ...uploadedFileDetails]);
        this.currentlyUploadingFiles$.next([]);

        this._emitUploadedFiles();
      }
    });
  }


  trackByFn(index, item: FileDetails) {
    return item.fileName; // or item.id
  }

  uploadFilesWithProgress(fileUploads: Array<FileUploadData>): Observable<FileUploadResponseProgressData> {
    this._updateProgress(0);

    const formData: FormData = new FormData();
    const filesData: Array<{ sanitizedFileName: string }> = [];
    for (const preparedFile of fileUploads) {
      formData.append('files', preparedFile.file, preparedFile.sanitizedFileName);
      filesData.push({ sanitizedFileName: preparedFile.sanitizedFileName });
    }

    for (let i = 0; i < filesData.length; i++) {
      formData.append(`filesData[${i}][sanitizedFileName]`, filesData[i].sanitizedFileName);
    }

    //TODO: use the correct API call like in asset -import -preview.component.ts and change add[HttpPost] in the controller
    return this._httpClient.post<any>(`/api/file/uploadFiles`, formData, { reportProgress: true, observe: 'events' })
      .pipe(
        map((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress: number = Math.round(100 * event.loaded / event.total);
              this._updateProgress(progress);
              return {
                status: 'upload_in_progress',
                progressValue: progress,
                uploadFinished: false,
                response: undefined,
                eventType: "UploadProgress"
              };
            case HttpEventType.Response:
              this._updateProgress(100);
              return {
                status: 'upload_finished',
                progressValue: 100,
                uploadFinished: true,
                response: event.body,
                eventType: "Response"
              };
            case HttpEventType.ResponseHeader:
              return {
                status: 'response_header',
                progressValue: -2,
                uploadFinished: false,
                response: undefined,
                eventType: "ResponseHeader"
              };
            case HttpEventType.Sent:
              return {
                status: 'sent',
                progressValue: -3,
                uploadFinished: false,
                response: undefined,
                eventType: "Sent"
              };
            default:
              return {
                status: 'unhandled_event',
                progressValue: -1,
                uploadFinished: false,
                response: undefined,
                eventType: event.type.toString()
              };
          }
        })
      );
  }

  removeUploadedFile(index: number) {
    let files = this.uploadedFiles$.value;
    files.splice(index, 1);
    this.uploadedFiles$.next(files);
  }
  removeTemporaryUploadedFile(index: number) {
    let files = this.temporaryUploadedFiles$.value;
    files.splice(index, 1);
    this.temporaryUploadedFiles$.next([...files]);
  }
  removeUploadingFile(index: number) {
    let files = this.currentlyUploadingFiles$.value;
    files.splice(index, 1);
    this.currentlyUploadingFiles$.next([...files]);
  }

  private _getFileNameWithoutExtension(fileName) {
    return fileName.replace(/\.[^/.]+$/, '');
  }

  private _getFileExtensionData(fileName): { extension: string, isImage: boolean } {
    const fileNameParts: Array<string> = fileName.split('.');
    if (fileNameParts.length === 1 || (fileNameParts[0] === '' && fileNameParts.length === 2)) {
      return { extension: '', isImage: false };
    }

    const extension: string = fileNameParts.pop().toLowerCase();
    const imageExtensions: Array<string> = ["jpg", "jpeg", "bmp", "tif", "tiff", "png"];
    const isImage: boolean = imageExtensions.indexOf(extension) > -1;

    return {
      extension: extension,
      isImage: isImage
    };
  }

  private _sanitizeFileName(fileNameWithoutExtension, fileExtension) {
    return fileNameWithoutExtension.replace(/[^a-z0-9]/gi, '_').replace(/_+/g, '_').toLowerCase() + (fileExtension ? '.' + fileExtension : '');
  }

  private _updateProgress(progress: number) {
    this._progress$.next(progress);
  }

  private _clearFileUploadElement() {
    this.fileUploadElement.nativeElement.value = "";
  }
}

