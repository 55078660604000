<div class="time-multiselect">
  <div class="time-multiselect-items">
    <div class="time-multiselect-items-item" *ngFor="let valueForm of otherPropertiesFormArray.controls; let valueFormIndex = index">
      <div class="time-multiselect-item">
        <div class="time-multiselect-item-number">{{ valueFormIndex + 1 }}.</div>
        <div class="bo-properties-content">


          <mat-form-field appearance="outline" class="full-width">
            <input matInput type="text" [formControl]="valueForm.controls.key">
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-select [formControl]="valueForm.controls.basedOnOtherPropertiesTypeKey" name="basedOnOtherPropertiesTypeKey">
              <mat-option *ngFor="let basedOnOtherPropertyType of basedOnOtherPropertyTypes" [value]="basedOnOtherPropertyType.key">
                {{basedOnOtherPropertyType.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <input matInput type="text" [formControl]="valueForm.controls.value">
          </mat-form-field>
        </div>
        <div class="time-multiselect-item-actions">
          <button mat-flat-button type="button" (click)="remove(valueFormIndex)" class="delete-icon-button"><i class="icofont-close"></i></button>
        </div>
      </div>
    </div>
  </div>
  <div class="time-multiselect-actions">
    <button mat-flat-button type="button" class="button-with-icon small-button" (click)="add()"><i class="icofont-plus"></i> Add</button>
  </div>
</div>
