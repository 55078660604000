import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { DEBOUNCE_TIME } from "../config/duration-config";


@Injectable({ providedIn: 'root' })
export class LoadingService {
  private _loadingCounter: number = 0;

  private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading$: Observable<boolean>;

  constructor() {
    this.isLoading$ = this._isLoading$.asObservable().pipe(debounceTime(DEBOUNCE_TIME));
  }

  public start() {
    this._loadingCounter++;
    this._isLoading$.next(true);
  }

  public stop() {
    this._loadingCounter--;
    if (this._loadingCounter < 0) {
      this._loadingCounter = 0;

    }
    if (this._loadingCounter === 0) {
      this._isLoading$.next(false);
    }
  }

  public reset() {
    this._loadingCounter = 0;
    this._isLoading$.next(false);
  }
}
