import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HotelGridData } from 'src/app/core/autogenerated-clients/api-client';
import { SelectedHotelGroupService } from '../../../core/services/hotel.service';

export class HotelSelection {
  hotelGroupId: string;
  hotelId: string;
}

@Component({
  selector: 'app-hotel-group-and-hotel-picker',
  templateUrl: './hotel-group-and-hotel-picker.component.html',
  styleUrls: ['./hotel-group-and-hotel-picker.component.scss']
})
export class HotelGroupAndHotelPickerComponent implements OnInit, OnDestroy {
  @Output() selectionChanged: EventEmitter<HotelSelection> = new EventEmitter<HotelSelection>();

  hotelSelectionForm: FormGroup;
  hotels$: BehaviorSubject<HotelGridData[]> = new BehaviorSubject<HotelGridData[]>([]);
  isLoadingHotelGroups$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _subscriptions: Subscription[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _selectedHotelGroupService: SelectedHotelGroupService,
  ) {
  }

  ngOnInit() {
    this._initForm();
  }

  ngOnDestroy(): void {
    for (let s of this._subscriptions) {
      s.unsubscribe();
    }
  }

  private _initForm(): void {
    let hotelGroupId: string = this._selectedHotelGroupService.getSelectedHotelGroupId();
    let hotelId: string = this._selectedHotelGroupService.getSelectedHotelId();

    this.hotelSelectionForm = this._formBuilder.group({
      hotelGroupId: [hotelGroupId],
      hotelId: [hotelId],
    });

    if (!hotelGroupId) {
      this.hotelSelectionForm.controls.hotelId.disable();
    }

    let s1: Subscription = this.hotelSelectionForm.controls.hotelGroupId.valueChanges.subscribe((hotelGroupId: string) => {
      this.hotelSelectionForm.controls.hotelId.setValue(null);

      let selection: HotelSelection = { hotelGroupId: hotelGroupId, hotelId: null };
      this.selectionChanged.next(selection);
    });
    this._subscriptions.push(s1);
  }

  onStartedLoadingHotelGroups(isSuccess: boolean): void {
    this.isLoadingHotelGroups$.next(true);
    this.hotelSelectionForm.controls.hotelId.disable();
  }

  onEndedLoadingHotelGroups(isSuccess: boolean): void {
    this.isLoadingHotelGroups$.next(false);
    this.hotelSelectionForm.controls.hotelId.enable();
  }

  onHotelSelected(hotelId: string): void {
    let selection: HotelSelection = { hotelGroupId: this.hotelSelectionForm.controls.hotelGroupId.value, hotelId: hotelId };
    this.selectionChanged.next(selection);
  }
}
