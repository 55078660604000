<mat-form-field appearance="outline" class="full-width" *ngIf="!(isLoadingHotelGroup$ | async)">
  <input type="text" placeholder="Select the hotel group" matInput [formControl]="hotelGroupAutocompleteControl" [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayHotelGroupName" (optionSelected)="onHotelGroupSelected($event)">
    <mat-option *ngFor="let group of filteredHotelGroups$ | async" [value]="group.id">
      {{group.name}}
    </mat-option>
  </mat-autocomplete>
  <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clearHotelGroupSelection()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
