import { Component, OnInit } from '@angular/core';
import { AuthorizeService, AuthenticationResultStatus } from '../authorize.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LoginActions, QueryParameterNames, ApplicationPaths, ReturnUrlType } from '../api-authorization.constants';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountClient, MasterLoginQuery } from 'src/app/core/autogenerated-clients/api-client';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from 'src/app/core/services/loading.service';

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public message = new BehaviorSubject<string>(null);


  loginForm: UntypedFormGroup;
  hidePasswordText: boolean;
  submitted: boolean;
  validationMessages: any;
  private returnUrl: string;


  constructor(
    private authorizeService: AuthorizeService,
    private accountClient: AccountClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public loading: LoadingService,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder
  ) {

    this.hidePasswordText = true;
    this.submitted = false;
    this.validationMessages = {
      'username': [
        { type: 'required', message: 'Required' }
      ],
      'password': [
        { type: 'required', message: 'Required' }
      ]
    };
  }

  async ngOnInit() {
    this.initForm();
    const action = this.activatedRoute.snapshot.url[1];
    switch (action.path) {
      case LoginActions.LoginCallback:
        await this.processLoginCallback();
        break;
      case LoginActions.LoginFailed:
        const message = this.activatedRoute.snapshot.queryParamMap.get(QueryParameterNames.Message);
        this.message.next(message);
        break;
    }
  }

  private async initForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  private async login(returnUrl: string): Promise<void> {
    const state: INavigationState = { returnUrl };
    const result = await this.authorizeService.signIn(state);
    this.message.next(undefined);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        break;
      case AuthenticationResultStatus.Success:
        await this.navigateToReturnUrl(returnUrl);
        break;
      case AuthenticationResultStatus.Fail:
        await this.router.navigate(ApplicationPaths.LoginFailedPathComponents, {
          queryParams: { [QueryParameterNames.Message]: result.message }
        });
        break;
      default:
        throw new Error(`Invalid status result ${(result as any).status}.`);
    }
  }

  private async processLoginCallback(): Promise<void> {
    const url = window.location.href;
    const result = await this.authorizeService.completeSignIn(url);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        // There should not be any redirects as completeSignIn never redirects.
        throw new Error('Should not redirect.');
      case AuthenticationResultStatus.Success:
        await this.navigateToReturnUrl(this.getReturnUrl(result.state));
        break;
      case AuthenticationResultStatus.Fail:
        this.message.next(result.message);
        break;
    }
  }

  private redirectToRegister(): any {
    this.redirectToApiAuthorizationPath(
      `${ApplicationPaths.IdentityRegisterPath}?returnUrl=${encodeURI('/' + ApplicationPaths.Login)}`);
  }

  private redirectToProfile(): void {
    this.redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
  }

  private async navigateToReturnUrl(returnUrl: string) {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    await this.router.navigateByUrl(returnUrl, {
      replaceUrl: true
    });
  }

  private getReturnUrl(state?: INavigationState): string {
    const fromQuery = (this.activatedRoute.snapshot.queryParams as INavigationState).returnUrl;
    // // If the url is comming from the query string, check that is either
    // // a relative url or an absolute url
    // if (fromQuery &&
    //   !(fromQuery.startsWith(`${window.location.origin}/`) ||
    //     /\/[^\/].*/.test(fromQuery))) {
    //   // This is an extra check to prevent open redirects.
    //   throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
    // }
    return (state && state.returnUrl) ||
      fromQuery ||
      ApplicationPaths.DefaultLoginRedirectPath;
  }

  private redirectToApiAuthorizationPath(apiAuthorizationPath: string) {
    // It's important that we do a replace here so that when the user hits the back arrow on the
    // browser they get sent back to where it was on the app instead of to an endpoint on this
    // component.
    const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
    window.location.replace(redirectUrl);
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.toastr.error('Please correct input and try again.');
      return;
    }

    this.loading.start();


    this.accountClient.login(new MasterLoginQuery({
      username: this.loginForm.controls.username.value,
      password: this.loginForm.controls.password.value,
      rememberMe: false
    })).subscribe(
      response => {
        if (response.isSuccess) {
          this.login(this.getReturnUrl());
        } else {
          this.toastr.error(response.message);
          this.loading.stop();
        }
      },
      error => {
        this.toastr.error(error);
        this.loading.stop();
      }
    );
  }
}

interface INavigationState {
  [ReturnUrlType]: string;
}
