<div class="borp-items">

  <div class="borp-item" [class.borp-item-selected]="reservationPropertiesFormGroup.controls.roomNameComparisonEnabled.value">
    <div class="borp-cbx form-field-wrapper">
      <mat-checkbox [formControl]="reservationPropertiesFormGroup.controls.roomNameComparisonEnabled"></mat-checkbox>
    </div>
    <div class="borp-property-name">Room name</div>
    <div class="borp-comparison-type form-field-wrapper">
      <mat-form-field appearance="outline" class="full-width">
        <mat-select [formControl]="reservationPropertiesFormGroup.controls.roomNameComparisonTypeKey">
          <mat-option *ngFor="let type of stringComparisonTypes" [value]="type.key">
            {{type.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="borp-comparison-value">
      <mat-form-field appearance="outline">
        <input [formControl]="reservationPropertiesFormGroup.controls.roomNameComparisonValue" matInput>
      </mat-form-field>
    </div>
  </div>

  <div class="borp-item" [class.borp-item-selected]="reservationPropertiesFormGroup.controls.bedNameComparisonEnabled.value">
    <div class="borp-cbx form-field-wrapper">
      <mat-checkbox [formControl]="reservationPropertiesFormGroup.controls.bedNameComparisonEnabled"></mat-checkbox>
    </div>
    <div class="borp-property-name">Bed name</div>
    <div class="borp-comparison-type form-field-wrapper">
      <mat-form-field appearance="outline" class="full-width">
        <mat-select [formControl]="reservationPropertiesFormGroup.controls.bedNameComparisonTypeKey">
          <mat-option *ngFor="let type of stringComparisonTypes" [value]="type.key">
            {{type.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="borp-comparison-value">
      <mat-form-field appearance="outline">
        <input [formControl]="reservationPropertiesFormGroup.controls.bedNameComparisonValue" matInput>
      </mat-form-field>
    </div>
  </div>

  <div class="borp-item" [class.borp-item-selected]="reservationPropertiesFormGroup.controls.guestNameComparisonEnabled.value">
    <div class="borp-cbx form-field-wrapper">
      <mat-checkbox [formControl]="reservationPropertiesFormGroup.controls.guestNameComparisonEnabled"></mat-checkbox>
    </div>
    <div class="borp-property-name">Guest name</div>
    <div class="borp-comparison-type form-field-wrapper">
      <mat-form-field appearance="outline" class="full-width">
        <mat-select [formControl]="reservationPropertiesFormGroup.controls.guestNameComparisonTypeKey">
          <mat-option *ngFor="let type of stringComparisonTypes" [value]="type.key">
            {{type.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="borp-comparison-value">
      <mat-form-field appearance="outline">
        <input [formControl]="reservationPropertiesFormGroup.controls.guestNameComparisonValue" matInput>
      </mat-form-field>
    </div>
  </div>

  <div class="borp-item" [class.borp-item-selected]="reservationPropertiesFormGroup.controls.vipComparisonEnabled.value">
    <div class="borp-cbx form-field-wrapper">
      <mat-checkbox [formControl]="reservationPropertiesFormGroup.controls.vipComparisonEnabled"></mat-checkbox>
    </div>
    <div class="borp-property-name">VIP</div>
    <div class="borp-comparison-type form-field-wrapper">
      <mat-form-field appearance="outline" class="full-width">
        <mat-select [formControl]="reservationPropertiesFormGroup.controls.vipComparisonTypeKey">
          <mat-option *ngFor="let type of stringComparisonTypes" [value]="type.key">
            {{type.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="borp-comparison-value">
      <mat-form-field appearance="outline">
        <input [formControl]="reservationPropertiesFormGroup.controls.vipComparisonValue" matInput>
      </mat-form-field>
    </div>
  </div>

  <div class="borp-item" [class.borp-item-selected]="reservationPropertiesFormGroup.controls.pmsNoteComparisonEnabled.value">
    <div class="borp-cbx form-field-wrapper">
      <mat-checkbox [formControl]="reservationPropertiesFormGroup.controls.pmsNoteComparisonEnabled"></mat-checkbox>
    </div>
    <div class="borp-property-name">PMS note</div>
    <div class="borp-comparison-type form-field-wrapper">
      <mat-form-field appearance="outline" class="full-width">
        <mat-select [formControl]="reservationPropertiesFormGroup.controls.pmsNoteComparisonTypeKey">
          <mat-option *ngFor="let type of stringComparisonTypes" [value]="type.key">
            {{type.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="borp-comparison-value">
      <mat-form-field appearance="outline">
        <input [formControl]="reservationPropertiesFormGroup.controls.pmsNoteComparisonValue" matInput>
      </mat-form-field>
    </div>
  </div>

  <div class="borp-item" [class.borp-item-selected]="reservationPropertiesFormGroup.controls.groupComparisonEnabled.value">
    <div class="borp-cbx form-field-wrapper">
      <mat-checkbox [formControl]="reservationPropertiesFormGroup.controls.groupComparisonEnabled"></mat-checkbox>
    </div>
    <div class="borp-property-name">Room name</div>
    <div class="borp-comparison-type form-field-wrapper">
      <mat-form-field appearance="outline" class="full-width">
        <mat-select [formControl]="reservationPropertiesFormGroup.controls.groupComparisonTypeKey">
          <mat-option *ngFor="let type of stringComparisonTypes" [value]="type.key">
            {{type.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="borp-comparison-value">
      <mat-form-field appearance="outline">
        <input [formControl]="reservationPropertiesFormGroup.controls.groupComparisonValue" matInput>
      </mat-form-field>
    </div>
  </div>

  <div class="borp-item" [class.borp-item-selected]="reservationPropertiesFormGroup.controls.numberOfAdultsComparisonEnabled.value">
    <div class="borp-cbx form-field-wrapper">
      <mat-checkbox [formControl]="reservationPropertiesFormGroup.controls.numberOfAdultsComparisonEnabled"></mat-checkbox>
    </div>
    <div class="borp-property-name">Number of adults</div>
    <div class="borp-comparison-type form-field-wrapper">
      <mat-form-field appearance="outline" class="full-width">
        <mat-select [formControl]="reservationPropertiesFormGroup.controls.numberOfAdultsComparisonTypeKey">
          <mat-option *ngFor="let type of numberComparisonTypes" [value]="type.key">
            {{type.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="borp-comparison-value">
      <mat-form-field appearance="outline">
        <input [formControl]="reservationPropertiesFormGroup.controls.numberOfAdultsComparisonValue" matInput>
      </mat-form-field>
    </div>
  </div>

  <div class="borp-item" [class.borp-item-selected]="reservationPropertiesFormGroup.controls.numberOfChildrenComparisonEnabled.value">
    <div class="borp-cbx form-field-wrapper">
      <mat-checkbox [formControl]="reservationPropertiesFormGroup.controls.numberOfChildrenComparisonEnabled"></mat-checkbox>
    </div>
    <div class="borp-property-name">Number of children</div>
    <div class="borp-comparison-type form-field-wrapper">
      <mat-form-field appearance="outline" class="full-width">
        <mat-select [formControl]="reservationPropertiesFormGroup.controls.numberOfChildrenComparisonTypeKey">
          <mat-option *ngFor="let type of numberComparisonTypes" [value]="type.key">
            {{type.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="borp-comparison-value">
      <mat-form-field appearance="outline">
        <input [formControl]="reservationPropertiesFormGroup.controls.numberOfChildrenComparisonValue" matInput>
      </mat-form-field>
    </div>
  </div>

  <div class="borp-item" [class.borp-item-selected]="reservationPropertiesFormGroup.controls.numberOfInfantsComparisonEnabled.value">
    <div class="borp-cbx form-field-wrapper">
      <mat-checkbox [formControl]="reservationPropertiesFormGroup.controls.numberOfInfantsComparisonEnabled"></mat-checkbox>
    </div>
    <div class="borp-property-name">Number of infants</div>
    <div class="borp-comparison-type form-field-wrapper">
      <mat-form-field appearance="outline" class="full-width">
        <mat-select [formControl]="reservationPropertiesFormGroup.controls.numberOfInfantsComparisonTypeKey">
          <mat-option *ngFor="let type of numberComparisonTypes" [value]="type.key">
            {{type.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="borp-comparison-value">
      <mat-form-field appearance="outline">
        <input [formControl]="reservationPropertiesFormGroup.controls.numberOfInfantsComparisonValue" matInput>
      </mat-form-field>
    </div>
  </div>




</div>


