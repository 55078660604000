import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HotelRoomData } from '../../../core/autogenerated-clients/api-client';
import { LoadingService } from '../../../core/services/loading.service';

@Component({
  selector: 'app-room-multiselect',
  templateUrl: './room-multiselect.component.html',
  styleUrls: ['./room-multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoomMultiselectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RoomMultiselectComponent),
      multi: true
    }
  ]
})
export class RoomMultiselectComponent implements OnInit, OnChanges, ControlValueAccessor, Validator, AfterViewInit {
  @ViewChild('multiselectInputOne', { static: true }) multiselectInput: ElementRef;
  @Input() allRooms: Array<HotelRoomData> = [];
  @Input() selectedRooms: Array<HotelRoomData> = [];
  @Input() isRequired: boolean = false;
  @Input() showMiniTags: boolean = false;
  @Input() placeholderText: string = "Room...";

  selectedRoomControl: UntypedFormControl;
  filteredRooms$: Observable<HotelRoomData[]>;

  selectedRooms$: BehaviorSubject<HotelRoomData[]> = new BehaviorSubject<HotelRoomData[]>([]);
  isValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private _toastr: ToastrService, public loading: LoadingService) { }
    ngAfterViewInit(): void {
    }

  ngOnInit(): void {
    this.selectedRoomControl = new UntypedFormControl("");

    this.filteredRooms$ = this.selectedRoomControl.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          if (typeof (value) === "string") {
            return this._filterRooms(value);
          }
          else {
            this.addSelectedRoom(value);
            return this.allRooms;
          }
        })
    );
  }

  public displayRoom(room: HotelRoomData): string {
    return '';
  }

  public blurAutocomplete() {
    this.multiselectInput.nativeElement.blur();
  }

  private _filterRooms(value: string): HotelRoomData[] {
    let valueParam: string = value.toLowerCase();
    return this.allRooms.filter(a => a.name.toLowerCase().indexOf(valueParam) > -1);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  public rooms: HotelRoomData[] = [];
  isDisabled: boolean = false;
  onChange = (_: any) => { }; // Called on a value change
  onTouched = () => { }; // Called if you care if the form was touched
  onValidatorChange = () => { }; // Called on a validator change or re-validation;

  addSelectedRoom(room: HotelRoomData): void {
    let roomIndex: number = this.rooms.findIndex(w => w.id === room.id);
    let rooms = [...this.rooms];

    if (roomIndex > -1) {
      rooms.splice(roomIndex, 1, room);
    }
    else {
      rooms.push(room);
    }

    this.rooms = rooms;
    this.onChange(rooms);
  }

  removeSelectedRoom(roomIndex: number): void {
    this.rooms.splice(roomIndex, 1);
    this.onChange(this.rooms);
  }

  // Writes a new value to the element -> from FormControl to the DOM
  // When FormControl is instantiated, setValue or patchValue
  writeValue(obj: any): void {
    this.rooms = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    let valid = true;

    if (!!this.rooms && this.rooms.length > 0) {
      this.rooms.forEach(yourEntry => valid = valid && !!yourEntry); // Perform here your single item validation
    }
    else {
      valid = !this.isRequired;
    }

    this.isValid$.next(valid);
    return valid ? null : { invalid: true };
  }
}
