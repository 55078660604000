import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { GetHotelGroupsQuery, HotelGroupClient, HotelGroupGridData } from '../../../core/autogenerated-clients/api-client';
import { SelectedHotelGroupService } from '../../../core/services/hotel.service';

@Component({
  selector: 'app-hotel-group-id-picker',
  templateUrl: './hotel-group-id-picker.component.html',
  styleUrls: ['./hotel-group-id-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotelGroupIdPickerComponent implements OnInit, OnDestroy {
  @Input() hotelGroupIdControl: UntypedFormControl;

  @Output() startedLoadingHotelGroups: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() endedLoadingHotelGroups: EventEmitter<boolean> = new EventEmitter<boolean>();

  hotelGroupAutocompleteControl: UntypedFormControl;

  groupChangedSubscription: Subscription;
  hotelGroups$: BehaviorSubject<HotelGroupGridData[]> = new BehaviorSubject<HotelGroupGridData[]>([]);
  filteredHotelGroups$: BehaviorSubject<HotelGroupGridData[]> = new BehaviorSubject<HotelGroupGridData[]>([]);
  isLoadingHotelGroup$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private hotelGroupClient: HotelGroupClient,
    private cookieService: CookieService,
    private _selectedHotelGroupService: SelectedHotelGroupService
  ) { }

  ngOnInit(): void {
    this._initAutocompleteControls();
    this._loadHotelGroups();
  }

  ngOnDestroy(): void {
    if (this.groupChangedSubscription) {
      this.groupChangedSubscription.unsubscribe();
    }
  }

  onHotelGroupSelected(optionEvent: MatAutocompleteSelectedEvent): void {
    let hotelGroupId: string = null;

    if (optionEvent && !!optionEvent.option) {
      hotelGroupId = optionEvent.option.value;
    }

    this._selectHotelGroupId(hotelGroupId);
  }

  displayHotelGroupName = (hotelGroupId: string) => {
    if (!hotelGroupId) {
      return null;
    }

    return this.hotelGroups$?.value.find(hg => hg.id === hotelGroupId)?.name;
  }

  clearHotelGroupSelection(): void {
    this._selectHotelGroupId(null);
    this.hotelGroupAutocompleteControl.setValue(null);
  }

  private _initAutocompleteControls(): void {
    this.hotelGroupAutocompleteControl = new UntypedFormControl();

    this.groupChangedSubscription = this.hotelGroupAutocompleteControl.valueChanges
      .subscribe(
        (group: any) => {
          this.filteredHotelGroups$.next(this._filter(group));
        }
      );
  }

  private _selectHotelGroupId(id: string) {
    // Reset the stored selected hotel id when the hotel group changes
    if (this.hotelGroupIdControl.value !== id) {
      this._selectedHotelGroupService.storeSelectedHotelId(null);
    }

    this._storeHotelGroupIdToCookie(id);
    this._selectedHotelGroupService.storeSelectedHotelGroupId(id);
    this.hotelGroupIdControl.setValue(id);
  }

  private _storeHotelGroupIdToCookie(id: string) {
    if (!id) {
      this.cookieService.delete("hotel_group_id", "/");
    }
    else {
      this.cookieService.set("hotel_group_id", id, null, "/");
    }
  }

  private _loadHotelGroups() {
    this.hotelGroupAutocompleteControl.disable();
    this.startedLoadingHotelGroups.next(true);
    this.isLoadingHotelGroup$.next(true);

    this.hotelGroupClient.getHotelGroups(new GetHotelGroupsQuery({})).subscribe(
      (hotelGroups: HotelGroupGridData[]) => {
        this.hotelGroups$.next(hotelGroups);
        this.filteredHotelGroups$.next([...hotelGroups]);

        let hotelGroupId: string = this.hotelGroupIdControl.value;
        if (!!hotelGroupId) {
          let hotelGroup = this.hotelGroups$.value.find(hg => hg.id === hotelGroupId);

          this.hotelGroupAutocompleteControl.setValue(hotelGroup.id);
          this.hotelGroupAutocompleteControl.updateValueAndValidity();
        }

        this.hotelGroupAutocompleteControl.enable();
      },
      (error: Error) => {
        this.isLoadingHotelGroup$.next(false);
        this.endedLoadingHotelGroups.next(false);
      },
      () => {
        this.isLoadingHotelGroup$.next(false);
        this.endedLoadingHotelGroups.next(true);
      }
    );
  }

  private _filter(value) {
    if (!value) {
      return this.hotelGroups$.value;
    }

    const filterValue = value.toLowerCase();
    return this.hotelGroups$.value.filter(a => a.name.toLowerCase().indexOf(filterValue) >= 0);
  }
}
