

  <mat-form-field appearance="outline" class="full-width">
    <mat-date-range-input [rangePicker]="filterRangePicker">
      <input matStartDate placeholder="Start date" [formControl]="startDateControl" (dateChange)="startDateChanged($event)" (click)="filterRangePicker.open()" (focus)="filterRangePicker.open()">
      <input matEndDate placeholder="End date" [formControl]="endDateControl" (dateChange)="endDateChanged($event)" (click)="filterRangePicker.open()" (focus)="filterRangePicker.open()">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="filterRangePicker">
      <i class="icofont-calendar" matDatepickerToggleIcon></i>
    </mat-datepicker-toggle>
    <mat-date-range-picker #filterRangePicker></mat-date-range-picker>
  </mat-form-field>
