import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-based-on-reservation-properties-multiselect',
  templateUrl: './based-on-reservation-properties-multiselect.component.html',
  styleUrls: ['./based-on-reservation-properties-multiselect.component.scss'],
})
export class BasedOnReservationPropertiesMultiselectComponent {

  @Input() reservationPropertiesFormGroup: UntypedFormGroup;

  stringComparisonTypes: Array<{ key: string, value: string, group: string }> = [
    { key: "EQUALS", value: "Equals", group: "SIMPLE_COMPARISON" },
    { key: "NOT_EQUALS", value: "Not equal", group: "SIMPLE_COMPARISON" },
    { key: "CONTAINS", value: "Contains", group: "SIMPLE_COMPARISON" },
    { key: "NOT_CONTAIN", value: "Not contain", group: "SIMPLE_COMPARISON" },
    { key: "STARTS_WITH", value: "Starts with", group: "SIMPLE_COMPARISON" },
    { key: "ENDS_WITH", value: "Ends with", group: "SIMPLE_COMPARISON" },
  ];

  numberComparisonTypes: Array<{ key: string, value: string, group: string }> = [
    { key: "EQUALS", value: "Equals", group: "SIMPLE_COMPARISON" },
    { key: "LESSER_THAN", value: "Lesser than", group: "SIMPLE_COMPARISON" },
    { key: "LESSER_THAN_OR_EQUAL", value: "Lesser or equal", group: "SIMPLE_COMPARISON" },
    { key: "GREATER_THAN", value: "Greater than", group: "SIMPLE_COMPARISON" },
    { key: "GREATER_THAN_OR_EQUAL", value: "Greater or equal", group: "SIMPLE_COMPARISON" },
  ];

  constructor() {
  }

  ngOnInit(): void {

  }
}
