<form [formGroup]="hotelSelectionForm" class="plugins-form group-and-hotel-picker">
  <div class="two-column-grid">
    <div>
      <app-hotel-group-id-picker [hotelGroupIdControl]="hotelSelectionForm.controls.hotelGroupId" (startedLoadingHotelGroups)="onStartedLoadingHotelGroups($event)" (endedLoadingHotelGroups)="onEndedLoadingHotelGroups($event)"></app-hotel-group-id-picker>
    </div>
    <div>
      <app-hotel-id-picker *ngIf="hotelSelectionForm.controls.hotelGroupId.value" [hotelIdControl]="hotelSelectionForm.controls.hotelId" [hotelGroupId]="hotelSelectionForm.controls.hotelGroupId.value" (hotelSelectionChanged)="onHotelSelected($event)"></app-hotel-id-picker>
    </div>
  </div>
</form>
