import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
//import { ConsoleReporter } from 'jasmine';
import { AuthorizeService } from 'src/api-authorization/authorize.service';

@Directive({
  selector: '[hasClaim]'
})
export class HasClaimDirective {
  @Input() set hasClaim(claimType: string | string[]) {
    if (this._authorizeService.hasClaim(claimType)) {
      // Add template to DOM
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      // Remove template from DOM
      this._viewContainer.clear();
    }
  }

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _authorizeService: AuthorizeService) {
  }
}

@Directive({
  selector: '[hasClaimValue]'
})
export class HasClaimValueDirective {
  @Input() set hasClaimValue(claim: { type: string, value: string }) {
    if (this._authorizeService.hasClaimValue(claim.type, claim.value)) {
      // Add template to DOM
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      // Remove template from DOM
      this._viewContainer.clear();
    }
  }

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _authorizeService: AuthorizeService) {
  }
}
