<div class="key-value-multiselect-item-component">
  <div class="key-value-item-key">
    <mat-form-field appearance="outline" class="full-width">
      <input matInput type="text"
             placeholder="Name"
             [formControl]="keyControl">
    </mat-form-field>
  </div>
  <div class="key-value-item-value">
    <mat-form-field appearance="outline" class="full-width">
      <input matInput type="text"
             placeholder="Value"
             [formControl]="valueControl">
    </mat-form-field>
  </div>
</div>
