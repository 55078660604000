import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';

export class TagItem {
  public key: string;
  public value: string;
}

@Component({
  selector: 'app-tags-multiselect',
  templateUrl: './tags-multiselect.component.html',
  styleUrls: ['./tags-multiselect.component.scss']
})
export class TagsMultiselectComponent implements OnInit {

  @Input() tags: Array<TagItem> = [];
  @Input() tagsFormArray: UntypedFormArray;
  @Output() tagAdded: EventEmitter<TagItem> = new EventEmitter<TagItem>();
  @Output() tagRemoved: EventEmitter<number> = new EventEmitter<number>();

  selectTagControl: UntypedFormControl;
  filteredTags$: Observable<Array<TagItem>>;

  constructor() {
  }

  ngOnInit(): void {
    this.selectTagControl = new UntypedFormControl('');
    //this.filteredTags$.next(this.tags);

    this.filteredTags$ = this.selectTagControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value) {
    if (typeof (value) === "string") {
      const filterValue = value.toLowerCase();
      return this.tags.filter(a => a.value.toLowerCase().indexOf(filterValue) >= 0);
    }
    else {
      return this.tags;
    }
  }

  displayTagName(tag) {
    if (tag && typeof (tag) === "object") {
      return tag.value;
    }
    return "";
  }

  removeTag(tagIndex: number) {
    this.tagRemoved.next(tagIndex);
  }

  addTag() {
    let tagValue: any = this.selectTagControl.value;
    if (typeof (tagValue) === "object") {
      this.tagAdded.next({
        key: tagValue.key,
        value: tagValue.value
      });
    }
    else {
      this.tagAdded.next({
        key: tagValue,
        value: tagValue
      });
    }

    this.selectTagControl.setValue('');
  }
}

