<mat-form-field appearance="outline" class="full-width">
  <input type="text" placeholder="Select the hotel" matInput [formControl]="hotelAutocompleteControl" [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayHotelName" (optionSelected)="onHotelSelected($event)">
    <mat-option *ngFor="let hotel of filteredHotels$ | async" [value]="hotel.id">
      {{hotel.name}}
    </mat-option>
  </mat-autocomplete>
  <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clearHotelSelection()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
