import { Component, Input} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProductListItem } from '../../../core/autogenerated-clients/api-client';

@Component({
  selector: 'app-based-on-other-properties-multiselect',
  templateUrl: './based-on-other-properties-multiselect.component.html',
  styleUrls: ['./based-on-other-properties-multiselect.component.scss'],
})
export class BasedOnOtherPropertiesMultiselectComponent {

  @Input() otherPropertiesFormArray: UntypedFormArray;

  basedOnOtherPropertyTypes: Array<{ key: string, value: string, group: string }> = [
    { key: 'EQUALS', value: 'Equal', group: 'SIMPLE_COMPARISON' },
    { key: 'NOT_EQUALS', value: 'Not equal', group: 'SIMPLE_COMPARISON' },
    { key: 'CONTAINS', value: 'Contain', group: 'SIMPLE_COMPARISON' },
    { key: 'NOT_CONTAIN', value: 'Not contain', group: 'SIMPLE_COMPARISON' },
    { key: 'BEGINS_WITH', value: 'Begins with', group: 'SIMPLE_COMPARISON' },
    { key: 'ENDS_WITH', value: 'Ends with', group: 'SIMPLE_COMPARISON' },
    { key: 'REGEX', value: 'Regex', group: 'REGEX' },
  ];

  constructor(private _formBuilder: UntypedFormBuilder, private _route: ActivatedRoute) {
  }

  ngOnInit(): void {

  }

  add() {
    this.otherPropertiesFormArray.push(this._createOtherPropertiesExtendedFormGroup());
  }

  remove(valueFormIndex: number) {
    this.otherPropertiesFormArray.removeAt(valueFormIndex);
  }

  private _createOtherPropertiesExtendedFormGroup() {
    return this._formBuilder.group({
      basedOnOtherPropertiesTypeKey: ['EQUALS'],
      key: [''],
      value: [''],
    });
  }
}
