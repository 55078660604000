<div class="time-multiselect">
  <div class="time-multiselect-items">
    <div class="time-multiselect-items-item" *ngFor="let valueForm of productsTagsFormArray.controls; let valueFormIndex = index">
      <div class="based-on-product-multiselect-item">
        <div class="time-multiselect-item-number">{{ valueFormIndex + 1 }}.</div>
        <div class="bopm-content">

          <mat-form-field appearance="outline" class="full-width">
            <mat-select [formControl]="valueForm.controls.basedOnProductsTagsTypeKey" name="basedOnProductsTagsTypeKey">
              <mat-option *ngFor="let basedOnProductType of basedOnProductTypes" [value]="basedOnProductType.key">
                {{basedOnProductType.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <ng-container *ngIf="valueForm.controls.basedOnProductsTagsTypeKey.value !== 'REGEX' && valueForm.controls.basedOnProductsTagsTypeKey.value !== 'EXISTING_PRODUCT'">
            <div class="case-sensitive-grid">
              <mat-checkbox [formControl]="valueForm.controls.isCaseSensitive">Case sensitive?</mat-checkbox>

              <mat-form-field appearance="outline" class="full-width">
                <input matInput type="text" [formControl]="valueForm.controls.comparisonValue">
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngIf="valueForm.controls.basedOnProductsTagsTypeKey.value === 'REGEX'">
            <mat-form-field appearance="outline" class="full-width">
              <input matInput type="text" [formControl]="valueForm.controls.comparisonValue">
            </mat-form-field>
          </ng-container>

          <div *ngIf="valueForm.controls.basedOnProductsTagsTypeKey.value === 'EXISTING_PRODUCT'">
            <mat-form-field appearance="outline" class="full-width">
              <mat-select [formControl]="valueForm.controls.productId" name="productId">
                <mat-option *ngFor="let product of allProducts" [value]="product.id">
                  {{product.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div *ngIf="valueForm.controls.productId.value">
              <mat-checkbox [formControl]="valueForm.controls.applyOnlyOnConsumptionDates">Apply only on consumption dates</mat-checkbox>
            </div>
          </div>
        </div>
        <div class="time-multiselect-item-actions">
          <button mat-flat-button type="button" (click)="remove(valueFormIndex)" class="delete-icon-button"><i class="icofont-close"></i></button>
        </div>
      </div>
    </div>
  </div>
  <div class="time-multiselect-actions">
    <button mat-flat-button type="button" class="button-with-icon small-button" (click)="add()"><i class="icofont-plus"></i> Add</button>
  </div>
</div>


