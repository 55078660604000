<div class="date-time-component">
  <div class="date-time-date">
    <mat-form-field class="example-full-width" appearance="outline">
      <input matInput [matDatepicker]="picker" [formControl]="dateControl" (click)="picker.open()">
      <mat-datepicker-toggle matSuffix [for]="picker">
        <i class="icofont-calendar" matDatepickerToggleIcon></i>
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="date-time-time">
    <mat-form-field appearance="outline" class="full-width">
      <input matInput type="text"
             placeholder="From"
             [formControl]="timeControl" [textMask]="{mask: mask}">
      <i matSuffix class="icofont-stopwatch"></i>
    </mat-form-field>
  </div>
</div>
